import '../css/style.scss'
import { ua } from './modules/ua'
import smoothscroll from 'smoothscroll-polyfill'
import inView from 'in-view'
import moment from 'moment'
import VueSlideUpDown from 'vue-slide-up-down'

// import Swiper from 'swiper/bundle'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.main = document.querySelector('#main')
    this.init()
  }

  init() {
    this.worksVue()
    this.initPages()
    this.setFillHeight()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    $('body').ripples({
      resolution: 700,
      dropRadius: 30,
      perturbance: 0.1,
      // interactive: false,
    })

    window.addEventListener('load', () => {
      document.querySelector('.openLoader').classList.add(klass.hidden)
    })

    // ナビゲーション開閉
    // document.querySelector('.navTrigger').addEventListener('click', () => {
    //   document.querySelector('body').classList.toggle(klass.nav)
    // })

    // // ナビゲーションを押下時に閉じる
    // Array.from(document.querySelectorAll('#gNav .siteMenu a')).forEach((trigger) => {
    //   trigger.addEventListener('click', () => {
    //     document.querySelector('body').classList.toggle('view-nav')
    //   })
    // })

    let vw = window.innerWidth
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) {
        return
      }
      vw = window.innerWidth
      this.setFillHeight()
    })

    /**
     * モーダルオープン
     */
    Array.from(document.querySelectorAll('.modal-open')).forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        const _target = btn.getAttribute('data-modal') || '.modalCommon'
        document.querySelector(_target).classList.toggle(klass.active)
      })
    })
    Array.from(document.querySelectorAll('.modal-close')).forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        Array.from(document.querySelectorAll('.modal')).forEach((m) => {
          m.classList.remove(klass.active)
        })
      })
    })

    inView('.inview').on('enter', (el) => {
      el.classList.add('is-view')
    })
    inView.offset(100)

    this.main.addEventListener('scroll', (e) => {
      // console.log(e.target.scrollTop)
      // console.log(e)
      if (e.target.scrollTop >= e.target.clientHeight) {
        document.querySelector('body').classList.add('has-header')
      } else {
        document.querySelector('body').classList.remove('has-header')
      }
    })
  }

  setFillHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  worksVue() {
    const worksVue = new Vue({
      el: '#works',
      components: {
        'vue-slide-up-down': VueSlideUpDown,
      },
      data() {
        return {
          loadFlag: true,
          loadWorks: false,
          current: {
            category: null,
            post: null,
            page: 1,
            totalPage: 1,
          },
          category: [],
          works: [],
        }
      },
      beforeCreate() {},
      mounted() {
        this.getCat()
        this.getData()
      },
      methods: {
        async getCat() {
          try {
            this.category = await $.ajax({
              type: 'GET',
              url: siteURL + '/wp-json/wp/v2/works-cat',
            })
          } catch (e) {
            console.log(e)
          }
        },
        async getData() {
          try {
            await $.ajax({
              type: 'GET',
              url: siteURL + '/wp-json/wp/v2/works?_embed',
            }).then((r, s, x) => {
              r.forEach((d) => {
                this.works.push(this.setData(d))
              })
              this.loadFlag = false
              this.loadWorks = true
              this.current.totalPage = Number(x.getResponseHeader('x-wp-totalpages'))
            })
          } catch (e) {
            console.log(e)
          }
        },
        async getMore() {
          try {
            document.querySelector('.openLoader').classList.add('is-load')
            const parm = this.current.category !== null ? `&works-cat=${this.current.category}` : ''
            this.current.page = this.current.page + 1
            this.loadFlag = true

            await $.ajax({
              type: 'GET',
              url: siteURL + `/wp-json/wp/v2/works?_embed&page=${this.current.page}${parm}`,
            }).then((r, s, x) => {
              r.forEach((d) => {
                this.works.push(this.setData(d))
              })
              this.loadFlag = false
            })
            document.querySelector('.openLoader').classList.remove('is-load')
          } catch (e) {
            console.log(e)
          }
        },

        // 実績詳細表示
        async worksOpen(_id, _idx) {
          try {
            if (_id === this.current.post) {
              this.current.post = null
              this.works[_idx].current = false
            } else {
              document.querySelector('.openLoader').classList.add('is-load')

              this.works.forEach((w) => {
                if (_id === w.id) {
                  setTimeout(() => {
                    this.scrollSet(_id)
                    w.current = true
                    this.slideSet(_id, _idx)
                  }, 500)
                } else {
                  w.current = _id === this.current.post
                }
              })
              this.current.post = _id

              setTimeout(() => {
                this.scrollSet(_id)
                document.querySelector('.openLoader').classList.remove('is-load')
              }, 600)
            }
          } catch (e) {
            console.log(e)
          }
        },

        // 実績詳細閉じる
        worksClose(_idx) {
          this.current.post = null
          this.works.forEach((w) => {
            w.current = false
          })
        },

        // Response Data 整形
        setData(d) {
          const c = []
          d._embedded['wp:term'][0].forEach((cat) => {
            c.push({
              name: cat.name,
              slug: cat.slug,
              id: cat.id,
              tax: cat.taxonomy,
            })
          })
          return {
            id: d.id,
            idName: `works${d.id}`,
            title: d.title.rendered,
            content: d.content.rendered,
            date: d.date,
            dateText: moment(d.date).format('MMM YYYY'),
            thumbnails: d._embedded['wp:featuredmedia'][0].media_details.sizes.imgPostImg.source_url,
            term: c,
            current: false,
            slide: {
              current: 0,
            },
            cf: {
              imgs: d.acf.imgs,
              team: d.acf.team,
              links: d.acf.links,
            },
          }
        },

        // PREV / NEXT
        async pager(_idx, _type, _id) {
          try {
            document.querySelector('.openLoader').classList.add('is-load')
            const _targetNum = _idx + _type
            const _target = this.works[_targetNum]
            const targetID = _target.id

            this.works.forEach((w) => {
              if (targetID !== w.id) {
                w.current = false
              }
            })
            this.current.post = targetID

            setTimeout(() => {
              this.scrollSet(targetID)
              document.querySelector('.openLoader').classList.remove('is-load')
              setTimeout(() => {
                _target.current = true
                this.slideSet(targetID, _targetNum)
              }, 400)
            }, 600)
          } catch (e) {
            console.log(e)
          }
        },
        scrollSet(_id) {
          const th = document.querySelector(`#works${_id}`).getBoundingClientRect()
          const scrollElement = document.querySelector('#works .secContent')
          const targetOffsetTop = scrollElement.scrollTop
          const _w = document.querySelector('body').clientWidth
          let _diff = 0

          if (860 > _w) {
            const _headH = document.querySelector('#works .secTitle').clientHeight
            _diff = th.top - _headH * 1.2
          } else {
            _diff = th.top - 123
          }

          scrollElement.scrollTo({
            top: targetOffsetTop + _diff,
            behavior: 'smooth',
          })
        },
        slideSet(_id, _idx) {
          const _targetID = `#works${_id}`
          const _target = this.works[_idx]
          _target.slide.current = 0

          Array.from(document.querySelectorAll(`${_targetID} .worksImgThumbnails img`)).forEach((img) => {
            img.addEventListener('click', (e) => {
              e.preventDefault()
              _target.slide.current = Number(img.getAttribute('data-num'))
            })
          })
        },
      },
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.blanks = new App()
})

window.addEventListener('DOMContentLoaded', () => {
  smoothscroll.polyfill()
  const anchorLinks = document.querySelectorAll('a[href^="#"]')
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks)

  anchorLinksArr.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const targetElement = document.querySelector(link.hash)
      const scrollElement = document.querySelector('#main')
      const targetOffsetTop = document.querySelector('#main').scrollTop + targetElement.getBoundingClientRect().top

      scrollElement.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth',
      })
    })
  })
  // anchorLinksArr.forEach((link) => {
  //   link.addEventListener('click', (e) => {
  //     e.preventDefault()
  //     const targetId = link.hash
  //     const targetElement = document.querySelector(targetId)
  //     const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top
  //     window.scrollTo({
  //       top: targetOffsetTop,
  //       behavior: 'smooth',
  //     })
  //   })
  // })
})
